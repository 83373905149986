import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import './Moon.css';

const MoonClock = () => {
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [moonPhase, setMoonPhase] = useState("");
  const [specialEvent, setSpecialEvent] = useState("");

  const fullMoons= useMemo(() => [
    '1930-01-14', 
    '1930-01-14', 
    '1930-02-13', 
    '1930-03-14', 
    '1930-04-13',
    '1930-05-12', 
    '1930-06-11', 
    '1930-07-10', 
    '1930-08-09',
    '1930-09-08', 
    '1930-10-07', 
    '1930-11-06', 
    '1930-12-06',
    
    '1931-01-04', 
    '1931-02-03', 
    '1931-03-04', 
    '1931-04-02',
    '1931-05-02', 
    '1931-05-31', 
    '1931-06-30', 
    '1931-07-29',
    '1931-08-28', 
    '1931-09-26', 
    '1931-10-26', 
    '1931-11-25',
    '1931-12-25',
    
    '1932-01-23', 
    '1932-02-22', 
    '1932-03-22',
    '1932-04-20', 
    '1932-05-20', 
    '1932-06-18', 
    '1932-07-17',
    '1932-08-16', 
    '1932-09-14', 
    '1932-10-14', 
    '1932-11-13',
    '1932-12-13', 
    '1933-01-11', 
    '1933-02-10', 
    '1933-03-12',
    '1933-04-10', 
    '1933-05-09', 
    '1933-06-08', 
    '1933-07-07',
    '1933-08-05', 
    '1933-09-04', 
    '1933-10-03', 
    '1933-11-02',
    '1933-12-02', 
    '1933-12-31', 
    '1934-01-30', 
    '1934-03-01',
    '1934-03-31', 
    '1934-04-29', 
    '1934-05-28', 
    '1934-06-27',
    '1934-07-26', 
    '1934-08-24', 
    '1934-09-23', 
    '1934-10-22',
    '1934-11-21', 
    '1934-12-20', 
    
  '1935-01-19',
  '1935-02-18',
  '1935-03-20',
  '1935-04-18',
  '1935-05-18',
  '1935-06-16',
  '1935-07-16',
  '1935-08-14',
  '1935-09-12',
  '1935-10-12',
  '1935-11-10',
  '1935-12-10',
  
  '1936-01-08',
  '1936-02-07',
  '1936-03-08',
  '1936-04-06',
  '1936-05-06',
  '1936-06-05',
  '1936-07-04',
  '1936-08-03',
  '1936-09-01',
  '1936-09-30',
  '1936-10-30',
  '1936-11-28',
  '1936-12-28',
  
  '1936-01-08',
  '1936-02-07',
  '1936-03-08',
  '1936-04-06',
  '1936-05-06',
  '1936-06-05',
  '1936-07-04',
  '1936-08-03',
  '1936-09-01',
  '1936-09-30',
  '1936-10-30',
  '1936-11-28',
  '1936-12-28',
  
  '1936-01-08',
  '1936-02-07',
  '1936-03-08',
  '1936-04-06',
  '1936-05-06',
  '1936-06-05',
  '1936-07-04',
  '1936-08-03',
  '1936-09-01',
  '1936-09-30',
  '1936-10-30',
  '1936-11-28',
  '1936-12-28',
  
  '1937-01-26',
  '1937-02-25',
  '1937-03-27',
  '1937-04-25',
  '1937-05-25',
  '1937-06-23',
  '1937-07-23',
  '1937-08-22',
  '1937-09-20',
  '1937-10-19',
  '1937-11-18',
  '1937-12-17',
  
  '1938-01-16',
  '1938-02-14',
  '1938-03-16',
  '1938-04-14',
  '1938-05-14',
  '1938-06-13',
  '1938-07-12',
  '1938-08-11',
  '1938-09-09',
  '1938-10-09',
  '1938-11-07',
  '1938-12-07',
  
  '1939-01-05',
  '1939-02-04',
  '1939-03-05',
  '1939-04-04',
  '1939-05-03',
  '1939-06-02',
  '1939-07-01',
  '1939-07-31',
  '1939-08-29',
  '1939-09-28',
  '1939-10-28',
  '1939-11-26',
  '1939-12-26',
  
  '1940-01-25',
  '1940-02-23',
  '1940-03-23',
  '1940-04-22',
  '1940-05-21',
  '1940-06-20',
  '1940-07-19',
  '1940-08-18',
  '1940-09-16',
  '1940-10-16',
  '1940-11-15',
  '1940-12-14',
  
  '1941-01-13',
  '1941-02-12',
  '1941-03-13',
  '1941-04-11',
  '1941-05-11',
  '1941-06-09',
  '1941-07-08',
  '1941-08-07',
  '1941-09-05',
  '1941-10-05',
  '1941-11-04',
  '1941-12-03',
  
  '1942-01-02',
  '1942-02-01',
  '1942-03-03',
  '1942-04-01',
  '1942-04-30',
  '1942-05-30',
  '1942-06-28',
  '1942-07-27',
  '1942-08-26',
  '1942-09-24',
  '1942-10-24',
  '1942-11-22',
  '1942-12-22',
  
  '1943-01-21',
  '1943-02-20',
  '1943-03-21',
  '1943-04-20',
  '1943-05-19',
  '1943-06-18',
  '1943-07-17',
  '1943-08-15',
  '1943-09-14',
  '1943-10-13',
  '1943-11-12',
  '1943-12-11',
  
  '1944-01-10',
  '1944-02-09',
  '1944-03-10',
  '1944-04-08',
  '1944-05-08',
  '1944-06-06',
  '1944-07-06',
  '1944-08-04',
  '1944-09-02',
  '1944-10-02',
  '1944-10-31',
  '1944-11-30',
  '1944-12-29',
  
  '1945-01-28',
  '1945-02-27',
  '1945-03-28',
  '1945-04-27',
  '1945-05-27',
  '1945-06-25',
  '1945-07-25',
  '1945-08-23',
  '1945-09-21',
  '1945-10-21',
  '1945-11-19',
  '1945-12-19',
  
  '1946-01-17',
  '1946-02-16',
  '1946-03-17',
  '1946-04-16',
  '1946-05-16',
  '1946-06-14',
  '1946-07-14',
  '1946-08-12',
  '1946-09-10',
  '1946-10-10',
  '1946-11-08',
  '1946-12-08',
  
  '1947-01-06',
  '1947-02-05',
  '1947-03-07',
  '1947-04-05',
  '1947-05-05',
  '1947-06-03',
  '1947-07-03',
  '1947-08-01',
  '1947-08-31',
  '1947-09-29',
  '1947-10-29',
  '1947-11-27',
  '1947-12-27',
  
  '1948-01-26',
  '1948-02-25',
  '1948-03-26',
  '1948-04-24',
  '1948-05-23',
  '1948-06-22',
  '1948-07-21',
  '1948-08-19',
  '1948-09-18',
  '1948-10-17',
  '1948-11-16',
  '1948-12-15',
  
  '1949-01-14',
  '1949-02-13',
  '1949-03-15',
  '1949-04-14',
  '1949-05-13',
  '1949-06-11',
  '1949-07-11',
  '1949-08-09',
  '1949-09-08',
  '1949-10-07',
  '1949-11-05',
  '1949-12-05',
  
  '1950-01-03',
  '1950-02-02',
  '1950-03-04',
  '1950-04-03',
  '1950-05-03',
  '1950-06-01',
  '1950-07-01',
  '1950-07-30',
  '1950-08-29',
  '1950-09-27',
  '1950-10-27',
  '1950-11-25',
  '1950-12-25',
  
  '1951-01-23',
  '1951-02-22',
  '1951-03-24',
  '1951-04-22',
  '1951-05-22',
  '1951-06-20',
  '1951-07-20',
  '1951-08-18',
  '1951-09-17',
  '1951-10-16',
  '1951-11-15',
  '1951-12-15',
  
  '1952-01-13',
  '1952-02-12',
  '1952-03-13',
  '1952-04-11',
  '1952-05-11',
  '1952-06-09',
  '1952-07-09',
  '1952-08-07',
  '1952-09-05',
  '1952-10-05',
  '1952-11-03',
  '1952-12-03',
  
  '1953-02-01',
  '1953-03-03',
  '1953-04-02',
  '1953-05-02',
  '1953-06-01',
  '1953-06-30',
  '1953-07-30',
  '1953-08-28',
  '1953-09-27',
  '1953-10-26',
  '1953-11-25',
  '1953-12-24',
  
  '1954-01-23',
  '1954-02-21',
  '1954-03-23',
  '1954-04-21',
  '1954-05-21',
  '1954-06-19',
  '1954-07-19',
  '1954-08-17',
  '1954-09-16',
  '1954-10-15',
  '1954-11-14',
  '1954-12-13',
  
  '1955-01-12',
  '1955-02-10',
  '1955-03-12',
  '1955-04-11',
  '1955-05-11',
  '1955-06-09',
  '1955-07-08',
  '1955-08-07',
  '1955-09-05',
  '1955-10-05',
  '1955-11-03',
  '1955-12-03',
  
  '1956-01-01',
  '1956-01-31',
  '1956-02-29',
  '1956-03-30',
  '1956-04-28',
  '1956-05-28',
  '1956-06-26',
  '1956-07-26',
  '1956-08-24',
  '1956-09-23',
  '1956-10-23',
  '1956-11-21',
  '1956-12-21',
  
  '1957-01-19',
  '1957-02-18',
  '1957-03-20',
  '1957-04-18',
  '1957-05-18',
  '1957-06-16',
  '1957-07-16',
  '1957-08-14',
  '1957-09-12',
  '1957-10-12',
  '1957-11-10',
  '1957-12-10',
  
  '1958-01-08',
  '1958-02-07',
  '1958-03-08',
  '1958-04-06',
  '1958-05-06',
  '1958-06-05',
  '1958-07-04',
  '1958-08-03',
  '1958-09-01',
  '1958-09-30',
  '1958-10-30',
  '1958-11-28',
  '1958-12-28',
  
  '1959-01-26',
  '1959-02-25',
  '1959-03-27',
  '1959-04-25',
  '1959-05-25',
  '1959-06-23',
  '1959-07-23',
  '1959-08-22',
  '1959-09-20',
  '1959-10-19',
  '1959-11-18',
  '1959-12-17',
  
  '1960-01-14',
  '1960-02-12',
  '1960-03-13',
  '1960-04-11',
  '1960-05-11',
  '1960-06-09',
  '1960-07-08',
  '1960-08-07',
  '1960-09-05',
  '1960-10-04',
  '1960-11-03',
  '1960-12-03',
  
  '1961-01-14',
  '1961-02-12',
  '1961-03-13',
  '1961-04-11',
  '1961-05-11',
  '1961-06-09',
  '1961-07-08',
  '1961-08-07',
  '1961-09-05',
  '1961-10-04',
  '1961-11-03',
  '1961-12-03',
  
  '1962-01-20',
  '1962-02-19',
  '1962-03-21',
  '1962-04-20',
  '1962-05-19',
  '1962-06-18',
  '1962-07-17',
  '1962-08-15',
  '1962-09-14',
  '1962-10-13',
  '1962-11-11',
  '1962-12-11',
  
  '1963-01-10',
  '1963-02-08',
  '1963-03-10',
  '1963-04-09',
  '1963-05-08',
  '1963-06-07',
  '1963-07-06',
  '1963-08-05',
  '1963-09-03',
  '1963-10-03',
  '1963-11-01',
  '1963-12-01',
  '1963-12-30',
  
  '1964-01-29',
  '1964-02-27',
  '1964-03-28',
  '1964-04-26',
  '1964-05-26',
  '1964-06-25',
  '1964-07-24',
  '1964-08-23',
  '1964-09-21',
  '1964-10-21',
  '1964-11-19',
  '1964-12-19',
  
  '1965-01-17',
  '1965-02-16',
  '1965-03-17',
  '1965-04-16',
  '1965-05-15',
  '1965-06-14',
  '1965-07-13',
  '1965-08-12',
  '1965-09-11',
  '1965-10-10',
  '1965-11-09',
  '1965-12-08',
  
  '1960-01-14',
  '1960-02-12',
  '1960-03-13',
  '1960-04-11',
  '1960-05-11',
  '1960-06-09',
  '1960-07-08',
  '1960-08-07',
  '1960-09-05',
  '1960-10-04',
  '1960-11-03',
  '1960-12-03',
  
  '1961-01-02',
  '1961-01-31',
  '1961-03-02',
  '1961-04-01',
  '1961-04-30',
  '1961-05-30',
  '1961-06-28',
  '1961-07-27',
  '1961-08-26',
  '1961-09-24',
  '1961-10-23',
  '1961-11-22',
  '1961-12-22',
  
  '1962-01-20',
  '1962-02-19',
  '1962-03-21',
  '1962-04-20',
  '1962-05-19',
  '1962-06-18',
  '1962-07-17',
  '1962-08-15',
  '1962-09-14',
  '1962-10-13',
  '1962-11-11',
  '1962-12-11',
  
  '1963-01-10',
  '1963-02-08',
  '1963-03-10',
  '1963-04-09',
  '1963-05-08',
  '1963-06-07',
  '1963-07-06',
  '1963-08-05',
  '1963-09-03',
  '1963-10-03',
  '1963-11-01',
  '1963-12-01',
  '1963-12-30',
  
  '1964-01-29',
  '1964-02-27',
  '1964-03-28',
  '1964-04-26',
  '1964-05-26',
  '1964-06-25',
  '1964-07-24',
  '1964-08-23',
  '1964-09-21',
  '1964-10-21',
  '1964-11-19',
  '1964-12-19',
  
  '1965-01-17',
  '1965-02-16',
  '1965-03-17',
  '1965-04-16',
  '1965-05-15',
  '1965-06-14',
  '1965-07-13',
  '1965-08-12',
  '1965-09-11',
  '1965-10-10',
  '1965-11-09',
  '1965-12-08',
  
  '1966-01-07',
  '1966-02-05',
  '1966-03-07',
  '1966-04-05',
  '1966-05-04',
  '1966-06-03',
  '1966-07-02',
  '1966-08-01',
  '1966-08-31',
  '1966-09-29',
  '1966-10-29',
  '1966-11-28',
  '1966-12-27',
  
  '1967-01-26',
  '1967-02-24',
  '1967-03-26',
  '1967-04-24',
  '1967-05-23',
  '1967-06-22',
  '1967-07-21',
  '1967-08-20',
  '1967-09-18',
  '1967-10-18',
  '1967-11-17',
  '1967-12-17',
  
  '1968-01-15',
  '1968-02-14',
  '1968-03-14',
  '1968-04-13',
  '1968-05-12',
  '1968-06-10',
  '1968-07-10',
  '1968-08-08',
  '1968-09-06',
  '1968-10-06',
  '1968-11-05',
  '1968-12-05',
  
  '1969-01-03',
  '1969-02-02',
  '1969-03-04',
  '1969-04-02',
  '1969-05-02',
  '1969-05-31',
  '1969-06-29',
  '1969-07-29',
  '1969-08-27',
  '1969-09-25',
  '1969-10-25',
  '1969-11-24',
  '1969-12-23',
  
  '1970-01-22',
  '1970-02-21',
  '1970-03-23',
  '1970-04-21',
  '1970-05-21',
  '1970-06-19',
  '1970-07-18',
  '1970-08-17',
  '1970-09-15',
  '1970-10-14',
  '1970-11-13',
  '1970-12-12',
  
  '1971-01-11',
  '1971-02-10',
  '1971-03-12',
  '1971-04-10',
  '1971-05-10',
  '1971-06-09',
  '1971-07-08',
  '1971-08-06',
  '1971-09-05',
  '1971-10-04',
  '1971-11-02',
  '1971-12-02',
  '1971-12-31',
  
  '1972-01-30',
  '1972-02-29',
  '1972-03-29',
  '1972-04-28',
  '1972-05-28',
  '1972-06-26',
  '1972-07-26',
  '1972-08-24',
  '1972-09-23',
  '1972-10-22',
  '1972-11-21',
  '1972-12-20',
  
  '1973-01-18',
  '1973-02-17',
  '1973-03-19',
  '1973-04-17',
  '1973-05-17',
  '1973-06-15',
  '1973-07-15',
  '1973-08-14',
  '1973-09-12',
  '1973-10-12',
  '1973-11-10',
  '1973-12-10',
  
  '1974-01-08',
  '1974-02-07',
  '1974-03-08',
  '1974-04-06',
  '1974-05-06',
  '1974-06-04',
  '1974-07-04',
  '1974-08-03',
  '1974-09-01',
  '1974-10-01',
  '1974-10-31',
  '1974-11-29',
  '1974-12-29',
  
  '1975-01-27',
  '1975-02-26',
  '1975-03-27',
  '1975-04-25',
  '1975-05-25',
  '1975-06-23',
  '1975-07-23',
  '1975-08-21',
  '1975-09-20',
  '1975-10-20',
  '1975-11-18',
  '1975-12-18',
  
  '1976-01-17',
  '1976-02-15',
  '1976-03-16',
  '1976-04-14',
  '1976-05-13',
  '1976-06-12',
  '1976-07-11',
  '1976-08-10',
  '1976-09-08',
  '1976-10-08',
  '1976-11-07',
  '1976-12-06',
  
  '1977-01-05',
  '1977-02-04',
  '1977-03-05',
  '1977-04-04',
  '1977-05-03',
  '1977-06-01',
  '1977-07-01',
  '1977-07-30',
  '1977-08-28',
  '1977-09-27',
  '1977-10-27',
  '1977-11-25',
  '1977-12-25',
  
  '1978-01-24',
  '1978-02-23',
  '1978-03-24',
  '1978-04-23',
  '1978-05-22',
  '1978-06-20',
  '1978-07-20',
  '1978-08-18',
  '1978-09-16',
  '1978-10-16',
  '1978-11-14',
  '1978-12-14',
  
  '1979-01-13',
  '1979-02-12',
  '1979-03-13',
  '1979-04-12',
  '1979-05-12',
  '1979-06-10',
  '1979-07-09',
  '1979-08-08',
  '1979-09-06',
  '1979-10-05',
  '1979-11-04',
  '1979-12-03',
  
  '1980-01-02',
  '1980-02-01',
  '1980-03-01',
  '1980-03-31',
  '1980-04-30',
  '1980-05-29',
  '1980-06-28',
  '1980-07-27',
  '1980-08-26',
  '1980-09-24',
  '1980-10-23',
  '1980-11-22',
  '1980-12-21',
  
  '1981-01-20',
  '1981-02-18',
  '1981-03-20',
  '1981-04-19',
  '1981-05-19',
  '1981-06-17',
  '1981-07-17',
  '1981-08-15',
  '1981-09-14',
  '1981-10-13',
  '1981-11-11',
  '1981-12-11',
  
  '1982-01-09',
  '1982-02-08',
  '1982-03-09',
  '1982-04-08',
  '1982-05-08',
  '1982-06-06',
  '1982-07-06',
  '1982-08-05',
  '1982-09-03',
  '1982-10-03',
  '1982-11-01',
  '1982-12-01',
  '1982-12-30',
  
  '1983-01-28',
  '1983-02-27',
  '1983-03-28',
  '1983-04-27',
  '1983-05-26',
  '1983-06-25',
  '1983-07-25',
  '1983-08-23',
  '1983-09-22',
  '1983-10-21',
  '1983-11-20',
  '1983-12-20',
  
  '1984-01-18',
  '1984-02-17',
  '1984-03-17',
  '1984-04-15',
  '1984-05-15',
  '1984-06-13',
  '1984-07-13',
  '1984-08-11',
  '1984-09-10',
  '1984-10-10',
  '1984-11-08',
  '1984-12-08',
  
  '1985-01-07',
  '1985-02-05',
  '1985-03-07',
  '1985-04-05',
  '1985-05-04',
  '1985-06-03',
  '1985-07-02',
  '1985-07-31',
  '1985-08-30',
  '1985-09-29',
  '1985-10-28',
  '1985-11-27',
  '1985-12-27',
  
  '1986-01-26',
  '1986-02-24',
  '1986-03-26',
  '1986-04-24',
  '1986-05-23',
  '1986-06-22',
  '1986-07-21',
  '1986-08-19',
  '1986-09-18',
  '1986-10-17',
  '1986-11-16',
  '1986-12-16',
  
  '1987-01-15',
  '1987-02-13',
  '1987-03-15',
  '1987-04-14',
  '1987-05-13',
  '1987-06-11',
  '1987-07-11',
  '1987-08-09',
  '1987-09-07',
  '1987-10-07',
  '1987-11-05',
  '1987-12-05',
  
  '1988-01-04',
  '1988-02-02',
  '1988-03-03',
  '1988-04-02',
  '1988-05-02',
  '1988-05-31',
  '1988-06-29',
  '1988-07-29',
  '1988-08-27',
  '1988-09-25',
  '1988-10-25',
  '1988-11-23',
  '1988-12-23',
  
  '1989-01-21',
  '1989-02-20',
  '1989-03-22',
  '1989-04-21',
  '1989-05-20',
  '1989-06-19',
  '1989-07-18',
  '1989-08-17',
  '1989-09-15',
  '1989-10-14',
  '1989-11-13',
  '1989-12-12',
  
  '1990-01-11',
  '1990-02-09',
  '1990-03-11',
  '1990-04-10',
  '1990-05-09',
  '1990-06-08',
  '1990-07-08',
  '1990-08-06',
  '1990-09-05',
  '1990-10-04',
  '1990-11-02',
  '1990-12-02',
  '1990-12-31',
  
  '1991-01-30',
  '1991-02-28',
  '1991-03-30',
  '1991-04-28',
  '1991-05-28',
  '1991-06-27',
  '1991-07-26',
  '1991-08-25',
  '1991-09-25',
  '1991-10-23',
  '1991-11-21',
  '1991-12-21',
  
  '1992-01-19',
  '1992-02-18',
  '1992-03-18',
  '1992-04-17',
  '1992-05-16',
  '1992-06-15',
  '1992-07-14',
  '1992-08-13',
  '1992-09-12',
  '1992-10-11',
  '1992-11-10',
  '1992-12-10',
  
  '1993-01-08',
  '1993-02-07',
  '1993-03-08',
  '1993-04-06',
  '1993-05-06',
  '1993-06-04',
  '1993-07-04',
  '1993-08-02',
  '1993-09-01',
  '1993-09-30',
  '1993-10-30',
  '1993-11-29',
  '1993-12-29',
  
  '1994-01-27',
  '1994-02-26',
  '1994-03-27',
  '1994-04-25',
  '1994-05-25',
  '1994-06-23',
  '1994-07-22',
  '1994-08-21',
  '1994-09-19',
  '1994-10-19',
  '1994-11-18',
  '1994-12-18',
  
  '1995-01-16',
  '1995-02-15',
  '1995-03-17',
  '1995-04-15',
  '1995-05-14',
  '1995-06-13',
  '1995-07-12',
  '1995-08-10',
  '1995-09-09',
  '1995-10-08',
  '1995-11-07',
  '1995-12-07',
  
  '1996-01-05',
  '1996-02-04',
  '1996-03-05',
  '1996-04-04',
  '1996-05-03',
  '1996-06-01',
  '1996-07-01',
  '1996-07-30',
  '1996-08-28',
  '1996-09-27',
  '1996-10-26',
  '1996-11-25',
  '1996-12-24',
  
  '1997-01-23',
  '1997-02-22',
  '1997-03-24',
  '1997-04-22',
  '1997-05-22',
  '1997-06-20',
  '1997-07-20',
  '1997-08-18',
  '1997-09-16',
  '1997-10-16',
  '1997-11-14',
  '1997-12-14',
  
  '1998-01-12',
  '1998-02-11',
  '1998-03-13',
  '1998-04-12',
  '1998-05-11',
  '1998-06-10',
  '1998-07-09',
  '1998-08-08',
  '1998-09-06',
  '1998-10-05',
  '1998-11-04',
  '1998-12-03',
  
  '1999-01-02',
  '1999-01-31',
  '1999-03-02',
  '1999-04-01',
  '1999-04-30',
  '1999-05-30',
  '1999-06-28',
  '1999-07-28',
  '1999-08-27',
  '1999-09-25',
  '1999-10-24',
  '1999-11-23',
  '1999-12-22',
  
  '2000-01-21',
  '2000-02-19',
  '2000-03-20',
  '2000-04-18',
  '2000-05-18',
  '2000-06-17',
  '2000-07-16',
  '2000-08-15',
  '2000-09-13',
  '2000-10-13',
  '2000-11-11',
  '2000-12-11',
  
  '2001-01-09',
  '2001-02-08',
  '2001-03-09',
  '2001-04-08',
  '2001-05-07',
  '2001-06-06',
  '2001-07-05',
  '2001-08-04',
  '2001-09-02',
  '2001-10-02',
  '2001-11-01',
  '2001-11-30',
  '2001-12-30',
  
  '2002-01-28',
  '2002-02-27',
  '2002-03-28',
  '2002-04-27',
  '2002-05-26',
  '2002-06-24',
  '2002-07-24',
  '2002-08-24',
  '2002-09-21',
  '2002-10-21',
  '2002-11-20',
  '2002-12-19',
  
  '2003-01-18',
  '2003-02-17',
  '2003-03-18',
  '2003-04-16',
  '2003-05-16',
  '2003-06-14',
  '2003-07-13',
  '2003-08-12',
  '2003-09-10',
  '2003-10-10',
  '2003-11-09',
  '2003-12-08',
  
  '2004-01-07',
  '2004-02-06',
  '2004-03-07',
  '2004-04-05',
  '2004-05-04',
  '2004-06-03',
  '2004-07-02',
  '2004-07-31',
  '2004-08-30',
  '2004-09-28',
  '2004-10-28',
  '2004-11-26',
  '2004-12-26',
  
  '2005-01-25',
  '2005-02-24',
  '2005-03-25',
  '2005-04-24',
  '2005-05-23',
  '2005-06-22',
  '2005-07-21',
  '2005-08-19',
  '2005-09-18',
  '2005-10-17',
  '2005-11-16',
  '2005-12-15',
  
  '2006-01-14',
  '2006-02-13',
  '2006-03-15',
  '2006-04-13',
  '2006-05-13',
  '2006-06-11',
  '2006-07-11',
  '2006-08-09',
  '2006-09-07',
  '2006-10-07',
  '2006-11-05',
  '2006-12-05',
  
  '2007-01-03',
  '2007-02-02',
  '2007-03-04',
  '2007-04-02',
  '2007-05-02',
  '2007-06-01',
  '2007-06-30',
  '2007-07-30',
  '2007-08-28',
  '2007-09-26',
  '2007-10-26',
  '2007-11-24',
  '2007-12-24',
  
  '2008-01-22',
  '2008-02-21',
  '2008-03-21',
  '2008-04-20',
  '2008-05-20',
  '2008-06-18',
  '2008-07-18',
  '2008-08-16',
  '2008-09-15',
  '2008-10-14',
  '2008-11-13',
  '2008-12-12',
  
  '2009-01-11',
  '2009-02-09',
  '2009-03-11',
  '2009-04-09',
  '2009-05-09',
  '2009-06-07',
  '2009-07-07',
  '2009-08-06',
  '2009-09-04',
  '2009-10-04',
  '2009-11-02',
  '2009-12-02',
  '2009-12-31',
  
  '2010-01-30',
  '2010-02-28',
  '2010-03-30',
  '2010-04-28',
  '2010-05-28',
  '2010-06-26',
  '2010-07-26',
  '2010-08-24',
  '2010-09-23',
  '2010-10-23',
  '2010-11-21',
  '2010-12-21',
  
  '2011-01-19',
  '2011-02-18',
  '2011-03-19',
  '2011-04-18',
  '2011-05-17',
  '2011-06-15',
  '2011-07-15',
  '2011-08-13',
  '2011-09-12',
  '2011-10-12',
  '2011-11-10',
  '2011-12-10',
  
  '2022-01-18',
  '2022-02-16',
  '2022-03-18',
  '2022-04-16',
  '2022-05-16',
  '2022-06-14',
  '2022-07-13',
  '2022-08-12',
  '2022-09-10',
  '2022-10-09',
  '2022-11-08',
  '2022-12-08',
  
  '2023-01-07',
  '2023-02-05',
  '2023-03-07',
  '2023-04-06',
  '2023-05-05',
  '2023-06-04',
  '2023-07-03',
  '2023-08-01',
  '2023-08-31',
  '2023-09-29',
  '2023-10-28',
  '2023-11-27',
  '2023-12-27',
  
  '2024-01-25',
  '2024-02-24',
  '2024-03-25',
  '2024-04-24',
  '2024-05-23',
  '2024-06-22',
  '2024-07-21',
  '2024-08-19',
  '2024-09-18',
  '2024-10-17',
  '2024-11-15',
  '2024-12-15',
  
  '2025-01-13',
  '2025-02-12',
  '2025-03-14',
  '2025-04-13',
  '2025-05-12',
  '2025-06-11',
  '2025-07-10',
  '2025-08-09',
  '2025-09-07',
  '2025-10-07',
  '2025-11-05',
  '2025-12-05',
  
  '2026-01-03',
  '2026-02-01',
  '2026-03-03',
  '2026-04-02',
  '2026-05-01',
  '2026-05-31',
  '2026-06-30',
  '2026-07-29',
  '2026-08-28',
  '2026-09-26',
  '2026-10-26',
  '2026-11-24',
  '2026-12-24',
  
  '2027-01-22',
  '2027-02-21',
  '2027-03-22',
  '2027-04-21',
  '2027-05-20',
  '2027-06-19',
  '2027-07-18',
  '2027-08-17',
  '2027-09-16',
  '2027-10-15',
  '2027-11-14',
  '2027-12-13',
  
  '2028-01-12',
  '2028-02-10',
  '2028-03-11',
  '2028-04-09',
  '2028-05-08',
  '2028-06-07',
  '2028-07-06',
  '2028-08-05',
  '2028-09-04',
  '2028-10-03',
  '2028-11-02',
  '2028-12-02',
  '2028-12-31',
  
  '2029-01-30',
  '2029-02-28',
  '2029-03-30',
  '2029-04-28',
  '2029-05-27',
  '2029-06-26',
  '2029-07-25',
  '2029-08-24',
  '2029-09-22',
  '2029-10-22',
  '2029-11-21',
  '2029-12-20',
  
  '2030-01-19',
  '2030-02-18',
  '2030-03-19',
  '2030-04-18',
  '2030-05-17',
  '2030-06-15',
  '2030-07-15',
  '2030-08-13',
  '2030-09-11',
  '2030-10-11',
  '2030-11-10',
  '2030-12-09',
  
  '2031-01-08',
  '2031-02-07',
  '2031-03-09',
  '2031-04-07',
  '2031-05-07',
  '2031-06-05',
  '2031-07-04',
  '2031-08-03',
  '2031-09-01',
  '2031-09-30',
  '2031-10-30',
  '2031-11-29',
  '2031-12-28',
  
  '2032-01-27',
  '2032-02-26',
  '2032-03-27',
  '2032-04-25',
  '2032-05-25',
  '2032-06-23',
  '2032-07-22',
  '2032-08-21',
  '2032-09-19',
  '2032-10-18',
  '2032-11-17',
  '2032-12-16',
  
  '2034-01-04',
  '2034-02-03',
  '2034-03-05',
  '2034-04-03',
  '2034-05-03',
  '2034-06-02',
  '2034-07-01',
  '2034-07-31',
  '2034-08-29',
  '2034-09-28',
  '2034-10-27',
  '2034-11-25',
  '2034-12-25'
      // Fügen Sie hier die Daten für den Vollmond ein
    ], []);
    
    const newMoons = useMemo(() =>  [
      
  '2024-01-11',
  '2024-02-09',
  '2024-03-10',
  '2024-04-08',
  '2024-05-08',
  '2024-06-06',
  '2024-07-06',
  '2024-08-04',
  '2024-09-03',
  '2024-10-02',
  '2024-11-01',
  '2024-12-01',
  '2024-12-30'
  
], []);
    
    const redMoons = useMemo(() => [
       
  '2024-01-11',
  '2024-02-09',
  '2024-03-10',
  '2024-04-08',
  '2024-05-08',
  '2024-06-06',
  '2024-07-06',
  '2024-08-04',
  '2024-09-03',
  '2024-10-02',
  '2024-11-01',
  '2024-12-01',
  '2024-12-30'
  
      
      // Fügen Sie hier weitere Blue Moon-Termine hinzu
    ], []);
  
    const blueMoons  = useMemo(() => [
      // Fügen Sie hier die Daten für den Blue Moon ein
       '2024-08-19', '2027-05-20', '2029-08-20'
      
      ], []);
  
    const strawberryMoons = useMemo(() =>  [
      // Fügen Sie hier die Daten für den Erdbeermoon ein
      '2024-06-21', 
    ], []);
  
    const solarEclipses= useMemo(() =>  [
       '2024-04-08', '2026-08-12', '2027-08-20',
      // Fügen Sie hier die Daten für die Sonnenfinsternis ein
    ], []);
  


    // Hier fügst du die Funktionen ein, um die Mondphase zu berechnen, spezielle Ereignisse zu erkennen, etc...

    useEffect(() => {
      // Funktion zur Berechnung der Mondphase für ein bestimmtes Datum
      const calculateMoonPhaseForDate = (date) => {
          const startDate = moment('1930-01-14',); // Startdatum ist der Vollmond am 14.01.1930
          const moonCycleDuration = 29.53058867;
          const daysSinceStart = moment(date).diff(startDate, "days");
          const totalMoonCycles = daysSinceStart / moonCycleDuration;
          const moonPhaseIndex = Math.round(totalMoonCycles);

          // Bereich für Vollmond und Neumond
          const fullMoonRange = 0.01; // 0.1 Tage vor und nach Vollmond
          const newMoonRange = 0.01; // 0.1 Tage vor und nach Neumond

          let emoji = "";

          if (totalMoonCycles % 1 <= fullMoonRange || totalMoonCycles % 1 >= 1 - fullMoonRange) {
              emoji = "🌕"; // Vollmond
          } else if (totalMoonCycles % 1 <= newMoonRange || totalMoonCycles % 1 >= 1 - newMoonRange) {
              emoji = "🌑"; // Neumond
          } else {
              const phase = Math.floor((totalMoonCycles % 1) * 15);
              const phases = ["🌕", "🌖", "🌖", "🌖", "🌗", "🌘", "🌘", "🌘", "🌑", "🌒", "🌒","🌒", "🌓", "🌔", "🌔", "🌔"];
              emoji = phases[phase];
          }

          setMoonPhase(emoji);

          return moonPhaseIndex;
      };

      const calculateUpcomingEvent = (date) => {
        const upcomingEventsData = [];
    
        if (fullMoons.includes(date)) {
            upcomingEventsData.push("Full Moon");
        }
    
        if (newMoons.includes(date)) {
            upcomingEventsData.push("New Moon");
        }
    
        if (redMoons.includes(date)) {
            upcomingEventsData.push("Red Moon");
        }
    
        if (blueMoons.includes(date)) {
            upcomingEventsData.push("Blue Moon");
        }
    
        if (strawberryMoons.includes(date)) {
            upcomingEventsData.push("Strawberry Moon");
        }
    
        if (solarEclipses.includes(date)) {
            upcomingEventsData.push("Solar Eclipse");
        }
    
        // Hier können Sie weitere Ereignisse hinzufügen und überprüfen
    
        // Die bevorstehenden Ereignisse können jetzt verwendet werden, um die speziellen Emojis zu erstellen
        const combinedEmoji = upcomingEventsData.map((event) => {
            switch (event) {
                case "Full Moon":
                    return "🌕";
                case "New Moon":
                    return "🌑";
                case "Blue Moon":
                    return "🔵";
                case "Red Moon":
                    return "🔴 ";
                case "Strawberry Moon":
                    return "🍓";
                case "Solar Eclipse":
                    return "☀️🌑";
                // Fügen Sie hier weitere spezielle Emojis hinzu
                default:
                    return "";
            }
        }).join(" ");
    
        setSpecialEvent(combinedEmoji);
    };
    

      calculateMoonPhaseForDate(selectedDate);
      calculateUpcomingEvent(selectedDate);
  }, [selectedDate, blueMoons, fullMoons, newMoons, redMoons, solarEclipses, strawberryMoons]);

  return (
      <div className="moon-clock">
          <h1>Moon Clock</h1>
          <label>
              <p className='text-moon'>Wählen Sie ein Datum, um die Mondphase anzuzeigen:</p>
              <input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
              />
          </label>
          <div className='to-the-moon'>
              <div className="date-phase">Mondphase am {selectedDate}:</div>
              <div className="moon-phase" style={{ fontSize: "84px" }}>
                  {specialEvent ? specialEvent : moonPhase}
              </div>
          </div>
      </div>
  );
};

export default MoonClock;